import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from "../components/common/Button"

const Event: React.FC = () => {
  const EventQueryData = useStaticQuery(graphql`
    query EventQuery {
      EventBanner: file(
        name: { eq: "event" }
        absolutePath: { regex: "/images/banner/" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }
    }
  `)

  const EventImg = getImage(EventQueryData.EventBanner)

  return (
    <>
      <section
        id="about"
        className="relative pb-8 pt-14 lg:pt-18 lg:pb-14 bg-theme_white  "
      >
        <div className="text-left mx-auto space-y-10">
          <h1 className="w-3/4 mx-auto text-lg xl:text-6xl font-poppins font-medium  text-transparent  bg-clip-text bg-gradient-to-r from-yellow-400 to-purple-600 ">
            Hacktoberfest 2023 & DevMeet in Kanyakumari
          </h1>
        </div>

        <div className="w-full p-4 px-10  flex flex-col md:flex-row lg:flex-row items-center justify-center">
          <div className=" w-full xl:w-2/5">
            {/* Image  Section  */}
            <div className="w-full px-4 mx-auto flex xl:items-center xl:justify-center">
              <GatsbyImage
                image={EventImg}
                alt="about"
                className="rounded-lg"
              />
            </div>
          </div>
          <div className="w-4/5 xl:w-1/2 px-4">
            <div className="text-left mx-auto space-y-5 items-center">
              {/* <h1 className="text-xl  text-transparent  bg-clip-text bg-gradient-to-r from-yellow-400 to-purple-600 font-bold font-Poppins">
                About Us
              </h1> */}
              <div className="text-left text-3xl font-bold font-Poppins text-white">
                What is
                <span className="text-transparent  bg-clip-text bg-gradient-to-r from-yellow-400 to-purple-600">
                  Hacktoberfest
                </span>{" "}
                ?
              </div>
              <div className="text-left text-white font-normal space-y-3">
                <p className="text-lg font-Poppins ">
                  Hacktoberfest is an annual global event that celebrates
                  open-source software and encourages contributions to various
                  projects on platforms like GitHub. It's a fantastic
                  opportunity for developers of all levels to give back to the
                  community, learn, and grow their skills.
                </p>
                <p className="text-lg font-Poppins ">
                  Join us for a memorable day of coding, networking, and
                  enjoying the unique charm of Kanyakumari. Let's celebrate
                  technology, creativity, and the open-source community together
                  at Hacktoberfest 2023 & DevMeet! 🥳
                </p>
                <Button
                  text="Register"
                  to="https://events.mlh.io/events/10215-hacktoberfest-2023-in-kanyakumari"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Event
